@import '../../styles/base.scss';

:local {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: $dark-grey--1;
    font-weight: normal;
    font-size: $font-size-s;
    margin-left: 12px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $font-size-xs;
    transition: $transition-fast;

    &:hover {
      opacity: .6;
    }
  }

  .iconWrapper {
    width: 18px;
    height: 18px;
  }
  .divider {
    height: 15px;
    width: 1px;
    background-color: $dark-grey--1;
    opacity: 1;
  }
  .label {
    opacity: 1;
    margin-top: 5px;
    cursor: pointer;
    &:hover {
      opacity: .6;
    }
  }
}