@import './styles/base.scss';

:local {
  .app {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
  }

  .intro {
    margin-top: 0px;
    @media screen and (max-width: $break-tablet-min) {
      margin-top: 60px;
    }
  }

  .scrollContainer {
    height: 5000px;
  }
}